import * as React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Page from '../components/Page/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <IndexLayout>
      <Page>
        <Container>
          <h1>{t('shell:404.header')}</h1>
          <p>
            {t('shell:404.p')} <Link to="/">{t('shell:404.link')}</Link>
          </p>
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default NotFoundPage;
